body {
  margin: 0;
  font-family: "avenir-book", Georgia, "Times New Roman", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiDataGrid-root {
  border-radius: 0px !important;
}

/* .MuiOutlinedInput-input {
  padding-right: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 40px;
}
*/

.MuiInput-input,
.MuiInput-root {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media (max-width: 700px) {
  .tcorp-print-container {
    margin: 20px;
    width: 100%;
  }
}

.tcorp-print-container {
  margin: 4px auto;
  width: fit-content;
  background: #fff;
  padding: 1px;
}

.label-card {
  max-width: 100%;
  min-width: 100%;
  height: 300px;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    /* margin-top: 1rem; */
    display: block;
    page-break-before: auto;
    width: 100vw;
    height: 100vh;
  }
}

.print-selection {
  width: 60vw;
  background: #fff;
  padding: 5px;
}

.print-container-scroll {
  height: 100vh;
  overflow-y: auto;
}

.print-nav {
  margin: 12px 0 0;
  display: flex;
  justify-content: space-between;
}

.csv-error {
  border: solid red 1px;
  color: red;
}

/* csv errors */
.csv-error-table {
  background: white;
  width: 99%;
  height: 92%;
  margin: 0 auto;
  padding: 14px;
  overflow: auto;
}
.csv-error-table th,
.csv-error-table td {
  border: solid 1px #ddd;
  padding: 5px;
}
.csv-error-fieldset {
  padding: 0px;
  width: fit-content;
  box-sizing: border-box;
}
.csv-error-input {
  border: none;
}
.csv-error {
  border: solid red 1px;
  color: red;
}
.csv-error-text {
  font-size: 10px;
  color: red;
  margin-bottom: -4px;
}

.badge {
  display: inline-block;
  padding: 0.45em 0.4em;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  width: 115px;
}

.blink {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
