/* 
.orders-nav-2 {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #DEE2E6;
} */
.mb-2 {
  margin-bottom: 20px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.h0 > div {
  height: unset !important;
}

.pointer {
  cursor: pointer;
}

input#react-csv-reader-input {
  display: none;
}

.csv-upload-btn {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  height: 36px;
  padding: 8px 16px 5px;
}

.csv-upload-btn:hover {
  cursor: pointer;
  background-color: #0069d9;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 8px !important;
  padding-bottom: 7px !important;
  border: 1px solid #ddd;
  border-radius: 4px;
}

input#orders_range,
input#orders_range::placeholder {
  opacity: 0.91;
  color: #42526e !important;
  font-family: inherit;
  font-size: 15px;
}

.rs-picker-toggle-caret {
  opacity: 0.65;
  color: #42526e !important;
  font-size: 15px;
}

.EnhancedTable-root-1,
.EnhancedTable-root-19 {
  margin-top: 0 !important;
}

.MuiTableSortLabel {
  opacity: 0.3;
}

.MuiIconButton-colorSecondary:hover {
  background-color: rgba(0, 245, 87, 0.04) !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #00f557 !important;
}

.EnhancedTableToolbar-highlight-15 {
  color: #00f557 !important;
  background-color: rgb(226, 255, 236) !important;
}

.print-container-scroll {
  height: 100vh;
  overflow-y: auto;
}

.tcorp-print-container {
  margin: 4px auto;
  width: fit-content;
  background: #fff;
  padding: 1px;
}

.print-nav {
  margin: 12px 0 0;
  display: flex;
  justify-content: space-between;
}

.label-div {
  width: 100%;
  margin: 0;
  height: 42px;
  padding-left: 4px;
  display: flex;
  flex-direction: row;
}

.label-div small {
  display: inline-block;
  width: 54px;
}

.label-card {
  max-width: 100%;
  min-width: 100%;
  height: 300px;
}

.label-div span {
  color: black;
  text-align: center;
  font-weight: bold;
}
@media (max-width: 700px) {
  .tcorp-print-container {
    margin: 20px;
    width: 100%;
  }
}

div#packageImage,
div#sigImage {
  width: 100%;
  background: #fff;
}

#packageImage img,
#sigImage img {
  height: 340px;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  background: #fff;
}

.sms-container {
  background: #fff;
  width: 187px;
  height: 38px;
  display: flex;
  justify-content: center;
  padding-top: 6px;
  padding-left: 16px;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  box-shadow: 0 5px 6px -6px #ddd;
}
